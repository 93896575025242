import { useState, useEffect, useRef } from "react";
//import {Link} from "react-router-dom";
import{Dropdown} from 'bootstrap';
import IMAGES from '../images';
import { read, utils, writeFile } from 'xlsx';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.min";
import { axiosURL } from '../util/baseURL';
import Loader from '../assets/images/infinity-loop.gif';
import TokenModal from './TokenModal';

const AdminUploadedRateHistory = () => {
	const [uploadedLocalRateData,setUploadedLocalRateData] = useState([]);
    const [uploadedCitybankRateData,setUploadedCitybankRateData] = useState([]);
    const [listLatestRates,setListLatestRates] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [searchDate, setSearchDate] = useState(new Date());
    const [currentPage,setCurrentPage] = useState(1);
    const [error,setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pagination,setPagination] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [totalRecords,setTotalRecords] = useState(0);
    const [isEditableLocalRate,setIsEditableLocalRate] = useState(false);
    const [isEditableCityBankRate,setIsEditableCityBankRate] = useState(false);
    const [isClickedEditableLocalRate, setIsClickedEditableLocalRate] = useState(false);
    const [isClickedEditableCityBankRate, setIsClickedEditableCityBankRate] = useState(false);

    const orderByCountry = useRef();
    const orderByCurrency = useRef();
    const orderByRegion = useRef();
    const searchDateRef = useRef(new Date());
    const listsOfRatesRef = useRef(0);

    const okBtnClick = ()=>{
        setShowModal(false);
        localStorage.clear();
        window.location.pathname = '/';
    }

    const userTokenData = JSON.parse( localStorage.getItem("userToken") );
    const getListsOfLatestRates = (pg,search="",orderByRegion="",orderByCountry="",orderByCurrency="")=> {
        setLoading(true);
        axiosURL.get('/get-latest-rates',{headers:{
                    'Authorization':'Bearer ' + userTokenData?.token
                  },params:{page: pg,search_date:search,orderByRegion,orderByCountry,orderByCurrency}}).then(res => {
                    console.log(res);
                        setListLatestRates(res?.data?.lists_of_latest_rates);
                        listsOfRatesRef.current = res?.data?.lists_of_latest_rates.length;
                        
                        setPagination(res?.data?.pagination);
                        setTotalRecords(res?.data?.total_records_found);
                        setLoading(false);
                  }).catch(err => {
                        if(err?.response?.data?.code == "invalid_or_expired_token"){
                            setShowModal(true);
                            
                        }
                  });
    }
    useEffect(() => {
        console.log("Blank")
        let d = new Date(searchDate);
        let month = d.getMonth() + 1;
        let day = d.getDate();
        let year = d.getFullYear();
        month = month.toString();
        day = day.toString();
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        let filterDate = [year,month,day].join('-');
		getListsOfLatestRates(1,filterDate)	;
        },[])
    
	useEffect(() => {
        console.log("Error")
            
        },[error,showModal])

    const handleOrderByRegion = (param) => {
        let sortBy = orderByRegion.current.getAttribute('data-sort');

        if(sortBy == 'ASC')
        {
            orderByRegion.current.setAttribute('data-sort','DESC');
        }
        else
        {
            orderByRegion.current.setAttribute('data-sort','ASC');
        }

        let d = new Date(searchDate);
        let month = d.getMonth() + 1;
        let day = d.getDate();
        let year = d.getFullYear();
        month = month.toString();
        day = day.toString();
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        let filterDate = [year,month,day].join('-');
        
        sortBy = orderByRegion.current.getAttribute('data-sort');
        let currentPage = document.querySelector(".page-item.active").textContent;
        if(currentPage == null){
            currentPage = 1;
        }
        
        if(document.querySelector(".country-name").classList.contains('current-select')){
            document.querySelector(".country-name").classList.remove('current-select');
            document.querySelector(".country-name").setAttribute('data-sort','ASC');
        }
        if(document.querySelector(".currency-name").classList.contains('current-select')){
            document.querySelector(".currency-name").classList.remove('current-select');
            document.querySelector(".currency-name").setAttribute('data-sort','ASC');
        }
        if(!document.querySelector(".region-name").classList.contains('current-select')){
            document.querySelector(".region-name").classList.add('current-select');
        }
        
        getListsOfLatestRates(currentPage,filterDate,sortBy,"","");

    }
   
    const handleOrderByCountry = (param) => {
        let sortBy = orderByCountry.current.getAttribute('data-sort');
        let keyword = "";
        if(sortBy == 'ASC')
        {
            orderByCountry.current.setAttribute('data-sort','DESC');
        }
        else
        {
            orderByCountry.current.setAttribute('data-sort','ASC');
        }

        let d = new Date(searchDate);
        let month = d.getMonth() + 1;
        let day = d.getDate();
        let year = d.getFullYear();
        month = month.toString();
        day = day.toString();
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        let filterDate = [year,month,day].join('-');
        
        sortBy = orderByCountry.current.getAttribute('data-sort');
        let currentPage = document.querySelector(".page-item.active").textContent;
        if(currentPage == null){
            currentPage = 1;
        }
        
        if(document.querySelector(".region-name").classList.contains('current-select')){
            document.querySelector(".region-name").classList.remove('current-select');
            document.querySelector(".region-name").setAttribute('data-sort','ASC');
        }
        if(document.querySelector(".currency-name").classList.contains('current-select')){
            document.querySelector(".currency-name").classList.remove('current-select');
            document.querySelector(".currency-name").setAttribute('data-sort','ASC');
        }
        if(!document.querySelector(".country-name").classList.contains('current-select')){
            document.querySelector(".country-name").classList.add('current-select');
        }
        
        getListsOfLatestRates(currentPage,filterDate,"",sortBy,"");

    }

    const handleOrderByCurrency = (param) => {
        let sortBy = orderByCurrency.current.getAttribute('data-sort');
         let keyword = "";
        if(sortBy == 'ASC')
        {
            orderByCurrency.current.setAttribute('data-sort','DESC');
        }
        else
        {
            orderByCurrency.current.setAttribute('data-sort','ASC');
        }

        let d = new Date(searchDate);
        let month = d.getMonth() + 1;
        let day = d.getDate();
        let year = d.getFullYear();
        month = month.toString();
        day = day.toString();
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        let filterDate = [year,month,day].join('-');
        
        sortBy = orderByCurrency.current.getAttribute('data-sort');
        let currentPage = document.querySelector(".page-item.active").textContent;
        if(currentPage == null){
            currentPage = 1;
        }
        
         if(document.querySelector(".region-name").classList.contains('current-select')){
            document.querySelector(".region-name").classList.remove('current-select');
            document.querySelector(".region-name").setAttribute('data-sort','ASC');
        }
        if(document.querySelector(".country-name").classList.contains('current-select')){
            document.querySelector(".country-name").classList.remove('current-select');
            document.querySelector(".country-name").setAttribute('data-sort','ASC');
        }
        if(!document.querySelector(".currency-name").classList.contains('current-select')){
            document.querySelector(".currency-name").classList.add('current-select');
        }
       
        
        getListsOfLatestRates(currentPage,filterDate,"","",sortBy);
    }
    const handleLocalRateUploadedFile = ($event) => {
        let allowedExtensions = /(\.csv|\.xlsx|\.xls)$/i;
             
        if (!allowedExtensions.exec(document.querySelector("#browseLocalRateFile").value)) {
            document.querySelector("#browseLocalRateFile").value = "";
            setError(true);
            return false;
        }

		const files = $event.target.files;
        if (files.length) {
            setError(false);
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
					//console.log(rows);
                    const unique = rows.filter(
                      (obj, index) =>
                        rows.findIndex((item) => item['Currency Code'] === obj['Currency Code']) === index
                    );

					setUploadedLocalRateData(unique);
					
                }
            }
            reader.readAsArrayBuffer(file);
        }
	}

    const handleCitybankRateUploadedFile = ($event) => {
        let allowedExtensions = /(\.csv|\.xlsx|\.xls)$/i;
             
        if (!allowedExtensions.exec(document.querySelector("#browseCitybankRateFile").value)) {
            document.querySelector("#browseCitybankRateFile").value = "";
            setError(true);
            return false;
        }

        const files = $event.target.files;
        if (files.length) {
            setError(false);
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    //console.log(rows);
                    const unique = rows.filter(
                      (obj, index) =>
                        rows.findIndex((item) => item['Currency Code'] === obj['Currency Code']) === index
                    );

                    setUploadedCitybankRateData(unique);
                    
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }

    const handleLocalRateUpload = () => {
        if(document.getElementById('browseLocalRateFile').files.length === 0) {
            setError(true);
            return false;
        }
        
        else{
            setError(false)
            let d = new Date(startDate);
            let month = d.getMonth() + 1;
            let day = d.getDate();
            let year = d.getFullYear();
            month = month.toString();
            day = day.toString();
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
            let uploadDate = [year,month,day].join('-');
            
            if( userTokenData?.token != "" ){
                document.querySelector(".btn-local-rate-upload").innerHTML = "Uploading..";
                document.querySelector(".btn-local-rate-upload").disabled = true;
                axiosURL.post('/upload-local-rate',{uploadedData : uploadedLocalRateData,uploadDate },{headers:{
                    'Authorization':'Bearer ' + userTokenData?.token
                  }}).then(res => {
                        document.getElementById("browseLocalRateFile").value = "";
                        document.querySelector(".btn-local-rate-upload").innerHTML = "Upload Rate";
                        document.querySelector(".btn-local-rate-upload").disabled = false;
                        document.querySelector(".btn-close").click();
                        setStartDate(new Date());
                        getListsOfLatestRates(1);
                  }).catch(err => {
                        if(err?.response?.data?.code == "invalid_or_expired_token"){
                            document.querySelector(".btn-close").click();
                            setShowModal(true);
                            
                        }
                  });
            }
        }
    }

    const handleCitybankRateUpload = () => {
        if(document.getElementById('browseCitybankRateFile').files.length === 0) {
            setError(true);
            return false;
        }
        
        else{
            setError(false)
            let d = new Date(startDate);
            let month = d.getMonth() + 1;
            let day = d.getDate();
            let year = d.getFullYear();
            month = month.toString();
            day = day.toString();
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
            let uploadDate = [year,month,day].join('-');
            
            if( userTokenData?.token != "" ){
                document.querySelector(".btn-citybank-rate-upload").innerHTML = "Uploading..";
                document.querySelector(".btn-citybank-rate-upload").disabled = true;
                axiosURL.post('/upload-citybank-rate',{uploadedData : uploadedCitybankRateData,uploadDate },{headers:{
                    'Authorization':'Bearer ' + userTokenData?.token
                  }}).then(res => {
                        document.getElementById("browseCitybankRateFile").value = "";
                        document.querySelector(".btn-citybank-rate-upload").innerHTML = "Upload Rate";
                        document.querySelector(".btn-citybank-rate-upload").disabled = false;
                        document.querySelector(".btn-close-modal-citybank").click();
                        setStartDate(new Date());
                        getListsOfLatestRates(1);
                  }).catch(err => {
                        if(err?.response?.data?.code == "invalid_or_expired_token"){
                            document.querySelector(".btn-close-modal-citybank").click();
                            setShowModal(true);
                            
                        }
                  });
            }
        }
    }

    const handleSearch = (filterDateValue) => {
        let d = new Date(filterDateValue);
        let month = d.getMonth() + 1;
        let day = d.getDate();
        let year = d.getFullYear();
        month = month.toString();
        day = day.toString();
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        let sDate = [year,month,day].join('-');
        getListsOfLatestRates(1,sDate);

    }

    const handlePagination = (pageNo,filterDate) => {
        let d = new Date(filterDate);
        let month = d.getMonth() + 1;
        let day = d.getDate();
        let year = d.getFullYear();
        month = month.toString();
        day = day.toString();
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        filterDate = [year,month,day].join('-');
        setCurrentPage(pageNo);
        getListsOfLatestRates(pageNo,filterDate);
    }

    const handleClickEditLocalRate = (target,rate)=> {
        target.parentNode.querySelector(".text-local-rate").setAttribute("style","display:none")
        target.parentNode.querySelector(".btn-update-localrate").removeAttribute("style");
        target.parentNode.querySelector(".btn-cancel-localrate").removeAttribute("style");
        target.parentNode.querySelector(".input-local-rate").removeAttribute("style");
        target.parentNode.querySelector(".input-local-rate").value = rate;
        target.parentNode.querySelector(".img-edit-local-rate").setAttribute("style","display:none");
    }

    const handleClickCancelLocalRate = (target,rate) => {
        target.parentNode.querySelector(".text-local-rate").innerHTML = rate;
        target.parentNode.querySelector(".text-local-rate").removeAttribute("style")
        target.parentNode.querySelector(".btn-update-localrate").setAttribute("style","display:none");
        target.parentNode.querySelector(".btn-cancel-localrate").setAttribute("style","display:none");
        target.parentNode.querySelector(".input-local-rate").setAttribute("style","display:none");
        target.parentNode.querySelector(".input-local-rate").value = "";
        target.parentNode.querySelector(".img-edit-local-rate").removeAttribute("style");


    }

    const handleUpdateLocalRate = (target,key,value) => {
        
        target.parentNode.querySelector(".text-local-rate").innerHTML = value;
        target.parentNode.querySelector(".text-local-rate").removeAttribute("style")
        target.parentNode.querySelector(".btn-update-localrate").setAttribute("style","display:none");
        target.parentNode.querySelector(".btn-cancel-localrate").setAttribute("style","display:none");
        target.parentNode.querySelector(".input-local-rate").setAttribute("style","display:none");
        target.parentNode.querySelector(".input-local-rate").removeAttribute("value");
        target.parentNode.querySelector(".img-edit-local-rate").removeAttribute("style");
        let d = new Date(searchDate);
        let month = d.getMonth() + 1;
        let day = d.getDate();
        let year = d.getFullYear();
        month = month.toString();
        day = day.toString();
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        let uploadDate = [year,month,day].join('-');
        axiosURL.post('/update-rate',{localRate : value,cityBankRate: '',currencyId: key,uploadDate },{headers:{
                    'Authorization':'Bearer ' + userTokenData?.token
          }}).then(res => {
            getListsOfLatestRates(currentPage,uploadDate);
                
          }).catch(err => {
                if(err?.response?.data?.code == "invalid_or_expired_token"){
                    document.querySelector(".btn-close").click();
                    setShowModal(true);
                    
                }
          });
    }

    const handleClickEditCitybankRate = (target,rate)=> {
        target.parentNode.querySelector(".text-citybank-rate").setAttribute("style","display:none")
        target.parentNode.querySelector(".btn-update-citybankrate").removeAttribute("style");
        target.parentNode.querySelector(".btn-cancel-citybankrate").removeAttribute("style");
        target.parentNode.querySelector(".input-citybankrate-rate").removeAttribute("style");
        target.parentNode.querySelector(".input-citybankrate-rate").value = rate;
        target.parentNode.querySelector(".img-edit-citybank-rate").setAttribute("style","display:none");
    }

    const handleClickCancelCitybankRate = (target,rate) => {
        target.parentNode.querySelector(".text-citybank-rate").innerHTML = rate;
        target.parentNode.querySelector(".text-citybank-rate").removeAttribute("style")
        target.parentNode.querySelector(".btn-update-citybankrate").setAttribute("style","display:none");
        target.parentNode.querySelector(".btn-cancel-citybankrate").setAttribute("style","display:none");
        target.parentNode.querySelector(".input-citybankrate-rate").setAttribute("style","display:none");
        target.parentNode.querySelector(".input-citybankrate-rate").value = "";
        target.parentNode.querySelector(".img-edit-citybank-rate").removeAttribute("style");


    }


    const handleUpdateCitybankRate = (target,key,value) => {
        
        target.parentNode.querySelector(".text-citybank-rate").innerHTML = value;
        target.parentNode.querySelector(".text-citybank-rate").removeAttribute("style")
        target.parentNode.querySelector(".btn-update-citybankrate").setAttribute("style","display:none");
        target.parentNode.querySelector(".input-citybankrate-rate").setAttribute("style","display:none");
        target.parentNode.querySelector(".input-citybankrate-rate").removeAttribute("value");
        target.parentNode.querySelector(".img-edit-citybank-rate").removeAttribute("style");
        let d = new Date(searchDate);
        let month = d.getMonth() + 1;
        let day = d.getDate();
        let year = d.getFullYear();
        month = month.toString();
        day = day.toString();
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        let uploadDate = [year,month,day].join('-');
        axiosURL.post('/update-rate',{localRate : '',cityBankRate: value,currencyId: key,uploadDate },{headers:{
                    'Authorization':'Bearer ' + userTokenData?.token
          }}).then(res => {
            getListsOfLatestRates(currentPage,uploadDate);
                
          }).catch(err => {
                if(err?.response?.data?.code == "invalid_or_expired_token"){
                    document.querySelector(".btn-close").click();
                    setShowModal(true);
                    
                }
          });
    }

    return(
    <>
         {
            showModal ? <TokenModal btnCallback={okBtnClick} /> : ''
        }
        


      {/*row heading start*/}
        <div className="row mb-2 mb-lg-4">
        <div className="col-md-12 d-lg-flex align-items-center main-hdng">
        <div className="col-md-7"><h1 className="page-title">Uploaded Rate History By Date</h1></div>
        <div className="col-md-5 d-flex justify-content-end">
        
        </div>
        </div>
        </div>
       
        {/*row heading end*/}
        
        
        {/*row table cont start*/}
        { loading ? <><div className="loader-outer"><img src={Loader} className="loader position-absolute"/></div></> : '' }
        <div className="light-body-bg shadow-lg py-4 px-4 rounded-4">
        
            
           
            <div id="datatables-reponsive_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
            {/*data table header start*/}
            <div className="d-md-flex align-items-center mb-3">
            <div className="col-auto d-block w-75 w-md-100 mb-xs-2"><h2 className="table-title text-white-50 fw-semibold d-inline-block">Currency Rate Chart</h2>
            <div className="ms-3 d-inline-block">
            <div className="text-center">
            <div className="dataTables_length"><h5 className="text-info mb-0">{totalRecords} results found</h5>
            </div>
            </div>
            </div>
            </div>
            <div className="col-auto d-block ms-auto ">
            <div className="text-end d-flex align-items-center">
            
            <DatePicker
                    dateFormat="MM/dd/yyyy"
                    showIcon
                    selected={searchDate}
                    onChange={(date) => {setSearchDate(date);searchDateRef.current=date;document.querySelector('.btn-go').click();}}
                    className="form-control date"
                    showYearDropdown
                    dateFormatCalendar="MMMM"
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                />
                <button className="btn btn-light rounded-0 top-0 end-0 z-9 btn-sm px-2 py-1 btn-go d-none" type="button" id="button-addon2" onClick={()=>handleSearch(searchDateRef.current)}>GO</button>
            
            
            
            </div>
            </div>
            </div>
            {/*data table header end*/}
            {/*data table start*/}
            {
                listsOfRatesRef.current == 0 ? <><h5 className="text-white mb-3 fs-4">Sorry. There are no rates. Please choose other date.</h5></> : <>
            <div className="table-responsive tableFixHead">

            <table id="datatables-column-search-select-inputs" className="table table-striped dataTable" width="100%" aria-describedby="datatables-column-search-select-inputs_info">
            <thead className="thead-dark">
            <tr role="row">
            <th className="sorting align-middle region-name" ref={orderByRegion} data-sort='ASC' onClick={(e)=>handleOrderByRegion(e.target)}>Region</th>
            <th className="sorting align-middle country-name current-select" ref={orderByCountry} data-sort='ASC' onClick={(e)=>handleOrderByCountry(e.target)}>Country Name</th>
            <th className="sorting align-middle currency-name" ref={orderByCurrency} data-sort='ASC' onClick={(e)=>handleOrderByCurrency(e.target)}>Currency</th>
            <th className="align-middle">EMNY/Local Market Rate</th>
            <th className="align-middle">Estimated ITS/Citibank Rate</th>
            
            </tr>
            </thead>

                           {/*<tfoot>
            <tr>
            <th className="align-middle"><input type="text" placeholder="Search" className="form-control input-sm w-75"/></th>
            <th className="align-middle"><input type="text" placeholder="Search" className="form-control input-sm w-75"/></th>
            <th className="align-middle"><input type="text" placeholder="Search" className="form-control input-sm w-75"/></th>
            <th className="align-middle">&nbsp;</th>
            <th className="align-middle">&nbsp;</th>
            
            </tr>
            </tfoot>*/}
            <tbody>
			
			{
				listLatestRates.map((item,i) => {
					
					return <>
					<tr role="row" className="odd" key={i} data-rowid={item?.row_id}>
						<td className="align-middle" key={Math.random()}>{item?.region}</td>
                        <td className="align-middle" key={Math.random()}>{item?.country_name}</td>
						<td className="align-middle" key={Math.random()}>{item?.currency_code}</td>
						<td className="align-middle" key={Math.random()}>
                            <span className="text-local-rate">{item?.local_market_rate}</span>
                            
                        </td>
						<td className="align-middle" key={Math.random()}>
                            <span className="text-citybank-rate">{item?.city_bank_rate}</span>
                            
                        </td>
                        
					</tr>
					</>
				})
			} 
           
            
            

            
            </tbody>
            </table>

            </div>
            </>
        }
            {/*data table end*/}
            {/*pagination start*/}
            <nav aria-label="Page navigation example" className="d-none">
                <ul className="pagination pagination-sm justify-content-end mt-4">
                    
                        
                             {[...Array(pagination)].map((x, i) =>
                                <li className="page-item active" key={i} onClick={()=>handlePagination(i+1,searchDate)}>
                                <a className="page-link" href="javascript:void(0)">{i+1}</a>
                            </li>
                          )}
                        
                        
                    
                    
                </ul>
            </nav>
            {/*pagination end*/}
            </div>
        
        </div>
        
        {/*row table cont end*/}
    </>
    );
}

export default AdminUploadedRateHistory;