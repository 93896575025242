import { useState, useEffect, useRef } from "react";
import { Modal } from 'bootstrap';
import {Link} from "react-router-dom";
import IMAGES from '../images';
import Loader from '../assets/images/infinity-loop.gif'
import axios from 'axios';
import TokenModal from './TokenModal';
//import Registration from './Registration';
import {axiosURL} from '../util/baseURL.js';
import RegistrationModalUser from "./RegistrationModalUser";

const AdminUserManagement = () => {
    
    
    const [AdminUsers, setAdminUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pagination,setPagination] = useState(0);
    const [totalRecords,setTotalRecords] = useState(0);
    const [sorganization, setSearchOrganization]=useState("");
    const [sname, setSearchName]=useState("");
    const [susertype, setSearchUsertype]=useState("");
    const [suserstatus, setSearchUserstatus]=useState("");
    let token = '';
    const userToken = JSON.parse( localStorage.getItem("userToken") );
    const okBtnClick = ()=>{
      setShowModal(false);
      localStorage.removeItem('userToken');
      window.location.pathname = '/';
    }
    const [search, setSearch] = useState('');
    const paginationRef = useRef(1);
    
     const getListsOfUsers = ( pg, organization="",username="",usertype="",status="")=> {
        setLoading(true);
        axiosURL.get('/getusers',{headers:{
        'Authorization':'Bearer ' + userToken?.token
        },params:{page: pg,
                 organization,
                  username,
                  usertype,
                  status}})
                  .then(res => {
            console.log(res?.data);
            setAdminUsers(res?.data?.listsofusers); 
            setPagination(res?.data?.pagination);
            setTotalRecords(res?.data?.total_records_found)
            setLoading(false);
        }).catch(err => {
            if(err?.res?.data?.code == "invalid_or_expired_token"){
                setShowModal(true);
                
            }
        });
    }
    
    const handlePagination = (pageNo) => {
        
      var organization = document.querySelector(".search-org").value != "" ?  document.querySelector(".search-org").value : "";
      var username = document.querySelector(".search-name").value != "" ?  document.querySelector(".search-name").value : "";
      var usertype = document.querySelector(".search-usertype").value != "All" ?  document.querySelector(".search-usertype").value : "";
      var userstatus = document.querySelector(".search-user-status").value != "" ?  document.querySelector(".search-user-status").value : "";
      getListsOfUsers(pageNo,organization,username,usertype,userstatus);
      
       
    }
               
    const handleSearch = () => {
        
      getListsOfUsers(1);
       
    }
    
    const handleSearchUsertype = (param) =>{
        //alert(param);
     getListsOfUsers(1,"","",param,"")
      
    }
    
    useEffect(() => {
    getListsOfUsers(1);
    },[])

   const confirmDeleteUserModal = (userId) => {
        document.getElementById("ok-btn").setAttribute("data-id",userId);
        let modalBox = new Modal(document.getElementById('deleteUserConfirmModal'));
        modalBox.show();
    }

    const deleteUser = (param) => {
        param.setAttribute("disabled",true);
        let userid = param.getAttribute('data-id');
        
        axiosURL.post('/delete-user',{userid},{headers:{
        'Authorization':'Bearer ' + userToken?.token
        }})
        .then(res => {
            document.querySelector(".cancel-confirm-btn").click();
            
                param.removeAttribute("disabled",false);
                getListsOfUsers(paginationRef.current);
            }).catch(err => {
            if(err?.res?.data?.code == "invalid_or_expired_token"){
                setShowModal(true);
                
            }
        });
    }
  
    return(
         
        
    <>
    {  showModal ? <TokenModal btnCallback={okBtnClick} /> : ''  }
                     
    <RegistrationModalUser/>
    <div className="modal fade" id="deleteUserConfirmModal" tabIndex="-1">
        <div className="modal-dialog ">
            <div className="modal-content">
                <div className="modal-header">
                <h1 className="modal-title fs-5 text-start" id="deleteUserConfirmModal">Are you sure you want to delete this user?</h1>
                <button type="button" className="btn-close btn-pos" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                
                       
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary cancel-confirm-btn me-2" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" id="ok-btn" data-id="" className="btn btn-primary" onClick={(e)=>deleteUser(e.target)}>Ok</button>
                    {/*<button type="button" id="ok-btn" data-id="" class="btn btn-primary">Ok</button>*/}
                </div>
            </div>
        </div>
    </div>

      {/*row heading start*/}
        <div className="row mb-2 mb-lg-4">
        <div className="col-md-12 d-lg-flex align-items-center main-hdng">
        <div className="col-md-7"><h1 className="page-title">User Management</h1></div>
        <div className="col-md-5 d-flex justify-content-end">
        <button type="button" className="btn btn-secondary me-3" data-bs-toggle="modal" data-bs-target="#addOrgModal">Add Organization <i className="ms-2 fa-solid fa-plus"></i></button>
        <Link to="/admin/registration"><button className="btn btn-secondary">Add User <i className="ms-2 fa-solid fa-plus"></i></button></Link>
        </div>
        </div>
        </div>
       
        {/*row heading end*/}
        
        { loading ? <><div className="loader-outer"><img src={Loader} className="loader position-absolute"/></div></> : '' }
        {/*row table cont start*/}
        <div className="light-body-bg shadow-lg py-4 px-4 rounded-4">
        
            <div id="datatables-reponsive_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
            {/*data table header start*/}
            <div className="d-flex align-items-center mb-3">
            <div className="col-auto d-block"><h2 className="table-title text-white-50 fw-semibold">User List</h2></div>
            <div className="col-auto d-block me-auto ms-3 ">
            <div className="text-center">
            <div className="dataTables_length"><h5 className="text-info mb-0">{totalRecords} results found</h5>
            </div>
            </div>
            </div>
            
            </div>
            {/*data table header end*/}
            {/*data table start*/}
            <div className="table-responsive tableFixHead">

            <table id="rowcout datatables-column-search-select-inputs" className="table table-striped dataTable" width="100%" aria-describedby="datatables-column-search-select-inputs_info">
            <thead className="thead-dark">
            <tr role="row">
            <th className="align-middle">Organization</th>
            <th className="align-middle">User Name</th>
            <th className="align-middle">User Type</th>
            <th className="align-middle">Status</th>
            <th className="align-middle">Last Login</th>
            <th className="align-middle">Registered At</th>
            <th className="align-middle">Last Modified</th>
            <th className="align-middle">Action</th>
            </tr>
            </thead>

            <tfoot>
            <tr>
            <th className="align-middle"><input type="text" 
         placeholder="Search" className="form-control input-sm w-75 search-org" 
         onChange ={(e) => {
              if(e.target.value.length == 3){

                 getListsOfUsers(1,e.target.value,"","","")
             } else if(e.target.value.length == 0){
                 
                getListsOfUsers(1,"","","","");
                 
             }
         }} />
                 </th>
            <th className="align-middle"><input type="text" placeholder="Search" className="form-control input-sm w-75 search-name"
           onChange ={(e) => {
             if(e.target.value.length == 3){
                getListsOfUsers(1,"",e.target.value,"","")
            }else if(e.target.value.length == 0){
                 
                getListsOfUsers(1,"","","","");
                 
             }
         }} /></th>
            <th className="align-middle">
                <select className="form-select input-sm search-usertype" onChange ={(e) => {
             handleSearchUsertype(e.target.value);
         }}>
                <option value="">All</option>
                <option value="2">Admin</option>
                <option value="0">User</option>
                </select>
            </th>
            <th className="align-middle">
                <select className="form-select input-sm w-75 search-user-status" onChange={(e)=>{
                    e.target.value == "" ? getListsOfUsers(1,"","","","") : getListsOfUsers(1,"","","",e.target.value);
                    
                }}>
                <option value="">All</option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
                </select>
            </th>
            <th className="align-middle"><button className="btn btn-sm btn-primary d-none clmn-srch" onClick={handleSearch}>Search</button></th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th className="align-middle"><button className="btn btn-sm btn-primary clear-srch" 
        onClick= {() => {
                  document.querySelector(".search-org").value = "";
                  document.querySelector(".search-name").value = "";
                  document.querySelector(".search-usertype").value = "";
                  document.querySelector(".search-user-status").value = "";
                  getListsOfUsers(1);
                 }}>Clear Search</button></th>
            </tr>
            </tfoot>
            <tbody>
        
        
            {AdminUsers
                     .filter((AdminUser) =>{
             if(search == "") {
                 return AdminUser;
             } else if(AdminUser.Organization.toLowerCase().includes(search.toLowerCase()) ||
               AdminUser.Username.toLowerCase().includes(search.toLowerCase())
               ) {
                 return AdminUser;
             }
         })
                     .map((AdminUser:any, index) => {
             return(
            <tr role="row" className="odd" key={index}>
            <td className="align-middle" key={index.Organization}>{AdminUser["Organization"]}</td>
            <td className="align-middle" key={index.Username}>{AdminUser["Username"]}</td>
            <td className="align-middle" key={index.UserType}>{AdminUser["UserType"]}</td>
            <td className="align-middle" key={index.Status}>{AdminUser["Status"]}</td>
            <td className="align-middle" key={index.Lastlogin}>{AdminUser["Lastlogin"]}</td>
            <td className="align-middle" key={index.RegisteredAt}>{AdminUser["RegisteredAt"]}</td>
            <td className="align-middle" key={index.LastModifiedAt}>{AdminUser["LastModifiedAt"]}</td>
              <td className="align-middle">
                 <Link className="d-inline-block align-middle me-2" to={"/admin/user-profile/" + AdminUser["ID"]}>
                <img src={IMAGES.EditIcon} alt="edit"/>
                </Link>
                <button className="d-inline-block align-middle me-1" onClick={()=>confirmDeleteUserModal(AdminUser["ID"])}>
                <img src={IMAGES.TrashIcon} alt="trash"/></button>
                
            </td>
            </tr>
             );
            })}
            </tbody>
            </table>
            </div>
            {/*data table end*/}
            {/*pagination start*/}
             <nav aria-label="Page navigation example">
                <ul className="pagination pagination-sm justify-content-end mt-4">
                    
                        
                             {[...Array(pagination)].map((x, i) =>
                                <li className="page-item" key={i} onClick={()=>{handlePagination(i+1);paginationRef.current = i+1}}>
                                <a className="page-link" href="javascript:void(0)">{i+1}</a>
                            </li>
                          )}

                </ul>
            </nav>
            {/*pagination end*/}
            </div>
        
        </div>
        
        {/*row table cont end*/}
    </>
    );
}
export default AdminUserManagement;