const AdminMasterData = () => {
  
    
  return (
    <>
      Master data goes here.
    </>
  );
};



export default AdminMasterData;