import { useState, useEffect, useRef } from "react";
import { Modal } from 'bootstrap';
import {Link} from "react-router-dom";
import IMAGES from '../images';
import Loader from '../assets/images/infinity-loop.gif'
import axios from 'axios';
import TokenModal from './TokenModal';
//import Registration from './Registration';
import {axiosURL} from '../util/baseURL.js';

const AuditLogManagement = () => {
    
    
    const [AuditLog, setAuditLog] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pagination,setPagination] = useState(0);
    const [totalRecords,setTotalRecords] = useState(0);
    const [sname, setSearchName]=useState("");
    let token = '';
    const userToken = JSON.parse( localStorage.getItem("userToken") );
    const okBtnClick = ()=>{
      setShowModal(false);
      localStorage.removeItem('userToken');
      window.location.pathname = '/';
    }
    
    const paginationRef = useRef(1);
    
     const getAuditLog = ( pg,q="")=> {
        setLoading(true);
        axiosURL.get('/get-audit-log',{headers:{
        'Authorization':'Bearer ' + userToken?.token
        },params:{page: pg,q}})
                  .then(res => {
            console.log(res?.data);
            setAuditLog(res?.data?.auditlog); 
            setPagination(res?.data?.pagination);
            setTotalRecords(res?.data?.total_records_found)
            setLoading(false);
        }).catch(err => {
            if(err?.res?.data?.code == "invalid_or_expired_token"){
                setShowModal(true);
                
            }
        });
    }
    
    const handlePagination = (pageNo) => {
        
      var sDate = "";
      //var username = document.querySelector(".search-name").value != "" ?  document.querySelector(".search-name").value : "";
      
      getAuditLog(pageNo,sDate);
      
       
    }
               
    const handleSearch = () => {
        
      getAuditLog(1);
       
    }
    
    
    useEffect(() => {
    getAuditLog(1);
    },[])

   
  
    return(
         
        
    <>
    {  showModal ? <TokenModal btnCallback={okBtnClick} /> : ''  }
                     
    
    

      {/*row heading start*/}
        <div className="row mb-2 mb-lg-4">
        <div className="col-md-12 d-lg-flex align-items-center main-hdng">
        <div className="col-md-7"><h1 className="page-title">Audit Log</h1></div>
        
        </div>
        </div>
       
        {/*row heading end*/}
        
        { loading ? <><div className="loader-outer"><img src={Loader} className="loader position-absolute"/></div></> : '' }
        {/*row table cont start*/}
        <div className="light-body-bg shadow-lg py-4 px-4 rounded-4">
        
            <div id="datatables-reponsive_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
            {/*data table header start*/}
            <div className="d-flex align-items-center mb-3">
            <div className="col-auto d-block me-auto ms-3 ">
            <div className="text-center">
            <div className="dataTables_length"><h5 className="text-info mb-0">{totalRecords} results found</h5>
            </div>
            </div>
            </div>
            
            </div>
            {/*data table header end*/}
            {/*data table start*/}
            <div className="table-responsive tableFixHead">

            <table id="rowcout datatables-column-search-select-inputs" className="table table-striped dataTable" width="100%" aria-describedby="datatables-column-search-select-inputs_info">
            <thead className="thead-dark">
            <tr role="row">
            <th className="align-middle">User Name</th>
            <th className="align-middle">IP Address</th>
            <th className="align-middle">Country</th>
            <th className="align-middle">State</th>
            <th className="align-middle">City</th>
            <th className="align-middle">Date</th>
            </tr>
            </thead>

            
            <tbody>
        
        
            {AuditLog.map((AuditLog:any, index) => {
             return(
            <tr role="row" className="odd" key={index}>
				<td className="align-middle" key={index.username}>{AuditLog["username"]}</td>
				<td className="align-middle" key={index.ip}>{AuditLog["ip"]}</td>
				<td className="align-middle" key={index.country}>{AuditLog["country"]}</td>
				<td className="align-middle" key={index.state}>{AuditLog["state"]}</td>
				<td className="align-middle" key={index.city}>{AuditLog["city"]}</td>
				<td className="align-middle" key={index.audit_log_date}>{AuditLog["audit_log_date"]}</td>
            </tr>
             );
            })}
            </tbody>
            </table>
            </div>
            {/*data table end*/}
            {/*pagination start*/}
             <nav aria-label="Page navigation example">
                <ul className="pagination pagination-sm justify-content-end mt-4">
                    
                        
                             {[...Array(pagination)].map((x, i) =>
                                <li className="page-item" key={i} onClick={()=>{handlePagination(i+1);paginationRef.current = i+1}}>
                                <a className="page-link" href="javascript:void(0)">{i+1}</a>
                            </li>
                          )}

                </ul>
            </nav>
            {/*pagination end*/}
            </div>
        
        </div>
        
        {/*row table cont end*/}
    </>
    );
}
export default AuditLogManagement;