import React,{ useState, useEffect } from 'react';
import { axiosURL } from '../util/baseURL';
import {Link} from "react-router-dom";
import IMAGES from '../images';

const AdminDashboard = () => {
    
	useEffect(() => {
        const get_ip = async () => {
		  console.log("got here");
		  const response = await fetch("https://geolocation-db.com/json/");
		  const data = await response.json();
		  console.log(data);
		  
		  const userTokenData = JSON.parse( localStorage.getItem("userToken") );
		  axiosURL.post('/audit-log',data,{headers:{
                    'Authorization':'Bearer ' + userTokenData?.token
                  }}).then(res => {
                        console.log(res);
                  }).catch(err => {
                        
                  });
		};
		get_ip();
      }, []);
    
	return(
    <>
        
{/*row heading start*/}
        <div className="row mb-2 mb-lg-4">
        <div className="col-md-12 d-lg-flex align-items-center main-hdng">
        <div className="col-md-7"><h1 className="page-title">Dashboard</h1></div>
        
        </div>
        </div>
       
{/*row heading end*/}
<div className="row">
  <div className="col-sm-4">
    <div className="card light-body-bg rounded-3">
      <Link to="/admin/usermanagement">
      <div className="card-body">
        <img src={IMAGES.UserManagement} className="card-img-top db-card-img mx-auto mb-4" alt="User Management"/>
        <h5 className="card-title text-white-50 text-center fs-4">User Management</h5>
      </div>
      </Link>
    </div>
  </div>
  <div className="col-sm-4">
    <div className="card light-body-bg rounded-3">
      <Link to="/admin/rateupload">
      <div className="card-body">
        <img src={IMAGES.RateUpload} className="card-img-top db-card-img mx-auto mb-4" alt="Rate Upload"/>
        <h5 className="card-title text-white-50 text-center fs-4">Rate Upload</h5>
      </div>
      </Link>
    </div>
  </div>
  <div className="col-sm-4">
    <div className="card light-body-bg rounded-3">
      <Link to="/admin/currencyandcountry">
      <div className="card-body">
        <img src={IMAGES.MasterData} className="card-img-top db-card-img mx-auto mb-4" alt="Master Data"/>
        <h5 className="card-title text-white-50 text-center fs-4">Master Data</h5>
      </div>
      </Link>
    </div>
  </div>
  <div className="col-sm-4">
    <div className="card light-body-bg rounded-3">
      <Link to="/admin/audit-log">
      <div className="card-body">
        <img src={IMAGES.Report} className="card-img-top db-card-img mx-auto mb-4" alt="Audit Log"/>
        <h5 className="card-title text-white-50 text-center fs-4">Audit Log</h5>
      </div>
      </Link>
    </div>
  </div>

</div>
    </>
    );
}

export default AdminDashboard;